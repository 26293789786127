import React from 'react'
import CareerHome from '../components/Career/CareerHome'
import OpenPosition from '../components/Career/OpenPosition'

const Careers = () => {
  return (
    <>
      <CareerHome />
      <OpenPosition />
    </>
  )
}

export default Careers