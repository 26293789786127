import React from "react";
import bg from "../../assets/career3Min.jpeg";
import { motion } from "framer-motion";
import BannerNav from "../BannerNav";

const CareerHome = () => {
  const careerPageLinks = [
    { label: "Home", url: "/", active: true },
    { label: "Careers", url: "#" },
  ];

  return (
    <div
      className={`w-full min-h-[70vh] lg:min-h-[85vh] px-4 sm:px-6 md:px-8 lg:px-12 relative flex flex-col justify-center bg-cover bg-no-repeat`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <BannerNav links={careerPageLinks} />
      {/* opacity over the bg image  */}
      <div className="absolute inset-0 bg-gray-600 opacity-80"></div>
      <div className="relative z-20 w-full px-4 md:px-8 lg:px-12 xl:px-16 text-center font-poppins">
        {/* Heading */}
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="font-extrabold md:text-6xl text-3xl text-white tracking-[3px] font-inter"
        >
          <span className="text-[#FFBF00]">Careers</span>
        </motion.p>

        {/*Description */}
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="mt-6 font-medium text-sm sm:text-base md:text-lg leading-relaxed text-white max-w-4xl mx-auto"
        >
          Join our dynamic team and shape your career with meaningful
          opportunities. Explore diverse roles, collaborate on innovative
          projects, and grow professionally within our inclusive and supportive
          work environment.
        </motion.p>
      </div>
    </div>
  );
};

export default CareerHome;
