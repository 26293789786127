import React from "react";
import img from "../../assets/EduNTTA.png";
import img1 from "../../assets/eduQues.png";
import img2 from "../../assets/edu.jpg";

const InteractiveLearning = () => {
  return (
    <div className=" px-4 sm:px-6 md:px-8 lg:px-12 font-poppins text-[#6E6E6E]">
      <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-0 py-8 mx-auto">
        <div className="flex-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10 md:w-24 md:h-24 lg:h-24 lg:w-24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
            />
          </svg>
          <h2 className="tracking-tight font-semibold md:text-4xl text-2xl text-[#6E6E6E] font-inter text-nowrap">
            <span className="text-[#FFBF00]"> Interactive </span> Learning
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Our E-learning Solutions are built around the concept of interactive
            learning, ensuring that educators and learners experience a dynamic
            and engaging educational journey. {`Here's`} how we achieve this
          </p>
        </div>
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img}
          alt="education"
        />
      </div>

      <div className="py-8 mx-auto">
        <h1 className="md:text-4xl text-2xl text-[#6E6E6E] font-medium tracking-tighter font-inter">
          <span className="text-[#FFBF00]">Learning Management System</span>{" "}
          (LMS)
        </h1>

        <ul className="pl-5 mt-3 space-y-2 list-disc list-inside text-sm sm:text-base md:text-lg leading-relaxed">
          <li>
            Our LMS boasts an intuitive and user-friendly interface. For
            educators, this means a seamless experience when creating, managing,
            etc.
          </li>
          <li>
            The LMS features progress tracking, which is instrumental in
            understanding how learners are progressing.
          </li>
          <li>
            There is a content library that offers access to an array of
            learning materials. These materials contain various formats,
            including multimedia and assessments.
          </li>
        </ul>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-0 py-8 mx-auto">
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img1}
          alt="education"
        />
        <div className="flex-1">
          <h2 className="tracking-tight font-semibold text-2xl md:text-4xl text-[#6E6E6E] font-inter">
            <span className="text-[#FFBF00]">Question</span> Bank
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            The question bank is a pivotal feature that supports the creation of
            interactive assessments and quizzes. Here's how it aids interactive
            learning: Vast Question Repository: Our question bank contains a
            wide array of questions covering various topics and difficulty
            levels. Educators can browse and select questions that best align
            with their course content and interactive learning objectives.
          </p>
        </div>
      </div>

      <div className="py-8 mx-auto">
        <h1 className="text-lg sm:text-xl md:text-2xl text-[#6E6E6E] font-medium tracking-tighter font-inter">
          <span className="text-[#FFBF00]">Accessibility</span>
        </h1>
        <p className="mt-3 text-sm sm:text-base md:text-lg leading-relaxed">
          These are made accessible to all learners. We provide to ensure that
          everyone, including those with diverse learning needs. These features
          work harmoniously to provide an interactive learning environment,
          fostering knowledge acquisition and retention for educators and
          learners alike. Interactive learning is at the core of our E-learning
          Solutions, and these features ensure a rich and immersive educational
          experience.
        </p>
      </div>

      <div className="py-8 mx-auto">
        <img className="mb-4" src={img2} alt="edu" />
        <h1 className="text-lg sm:text-xl md:text-2xl text-[#6E6E6E] font-medium tracking-tighter font-inter">
          <span className="text-[#FFBF00]"> Content Videos</span>
        </h1>
        <p className="mt-3 text-sm sm:text-base md:text-lg leading-relaxed">
          Video content is a powerful tool in enhancing the learning
          experience.We create visually engaging and informative videos that
          cater to various learning styles. Videos seamlessly integrate into our
          courses. Learners can access them within the LMS, making the learning
          process fluid and efficient. With our focus on interactive learning,
          our E-learning Solutions provide a dynamic and engaging educational
          experience that fosters knowledge acquisition and retention. These
          features empower educators to create impactful courses
        </p>
      </div>
    </div>
  );
};

export default InteractiveLearning;
