import React, { useState } from "react";
import { motion } from "framer-motion";

// Importing images
import img1 from "../assets/office-image-1.jpg";
import img2 from "../assets/office-image-2.jpg";
import img3 from "../assets/office-image-3.jpg";
import img4 from "../assets/office-image-4.jpg";
import img5 from "../assets/office-image-5.jpg";
import img6 from "../assets/office-image-6.jpg";

const images = [img1, img2, img3, img4, img5, img6];

const Gallery = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="flex flex-col justify-center items-center px-4 sm:px-6 lg:px-12 py-6"
    >
      <div className="text-[#6E6E6E] text-center py-6">
        <h2 className="mb-4 text-3xl md:text-4xl lg:text-5xl tracking-tight font-semibold text-[#FFBF00] font-inter">
          Our Creative <span className="text-[#6E6E6E]">Work Culture</span>
        </h2>
        <p className="max-w-3xl text-base md:text-xl my-6 leading-relaxed mx-auto">
          At the heart of our team is collaboration, creativity, and fun! We
          foster an environment where ideas flow freely, growth is a shared
          journey, and every achievement is celebrated together.
        </p>
      </div>

      <div
        className="relative overflow-hidden w-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <motion.div
          className="flex space-x-10"
          animate={isHovered ? { x: 0 } : { x: ["0%", "-100%"] }}
          transition={{
            duration: 35,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          {images.concat(images).map((img, index) => (
            <motion.div
              key={index}
              className="flex-shrink-0"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 20px 60px rgba(0, 0, 0, 0.2)",
                transition: { duration: 0.3 },
              }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <div className="bg-white shadow-lg rounded-xl overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:border-[#FFBF00]">
                <motion.img
                  src={img}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-96 object-cover rounded-xl transition-transform duration-300 ease-in-out"
                  initial={{ scale: 1 }}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 15px 45px rgba(0, 0, 0, 0.3)",
                  }}
                  transition={{ duration: 0.7 }}
                />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Gallery;
