import React from "react";

const OpenPosition = () => {
  const jobs = [
    { title: "Software Engineer - I", location: "On-Site" },
    { title: "Full Stack Developer - Intern", location: "On-Site" },
    { title: "Marketing & Sales Developer", location: "On-Site" },
    { title: "Graphics Designer", location: "On-Site" },
    { title: "Angular Developer", location: "On-Site" },
  ];

  const applyNow = (title) => {
    const email = "info@mindmatrixsolutions.in";
    const subject = `Application for ${title}`;
    const body = `I am interested in applying for the ${title} position.`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-12 font-poppins text-[#6E6E6E]">
      <div className="py-8 flex flex-col justify-center items-center text-center">
        <h1 className="md:text-4xl text-2xl text-[#6E6E6E] font-semibold tracking-tighter font-inter">
          <span className="text-[#FFBF00]">Open</span> Positions
        </h1>
        <p className="max-w-3xl mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
          Our team is actively looking for skilled individuals to join our team.
          We have vacancies in multiple departments, offering diverse
          opportunities.
        </p>
      </div>

      <ul className="max-w-4xl mx-auto divide-y divide-gray-400 bg-gray-800 rounded-lg shadow-lg md:p-6 my-8">
        {jobs.map((job, index) => (
          <li key={index} className="py-4 sm:py-5">
            <div className="flex flex-col sm:flex-row items-center justify-between space-y-3 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0 text-center sm:text-left">
                  <p className="text-lg md:text-xl font-semibold text-[#FFBF00] underline">
                    {job.title}
                  </p>
                  <p className="text-sm text-gray-400">{job.location}</p>
                </div>
              </div>
              <button
                className="px-4 py-2 text-sm md:text-base font-semibold text-white bg-[#FFBF00] hover:bg-[#f2c53e] rounded-lg transition"
                onClick={() => applyNow(job.title)}
              >
                Apply
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OpenPosition;
