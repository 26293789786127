import React, { useRef } from "react";
import Slider from "react-slick";
import card1 from "../assets/blogImg1.webp";
import card2 from "../assets/blogImg2.webp";
import card3 from "../assets/blogImg3.webp";
import card4 from "../assets/blogImg4.webp";
import card1Min from "../assets/blogImg1Min.jpg";
import card2Min from "../assets/blogImg2Min.jpg";
import card3Min from "../assets/blogImg3Min.jpg";
import card4Min from "../assets/blogImg4Min.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function Blogs() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      link: "/education",
      limg: card1Min,
      himg: card1,
      title: "E-Learning Services",
      subtitle: "Online Education for Better Learning Outcomes",
      description:
        "Our E-learning Solutions are built around interactive learning to foster a more dynamic environment.",
    },
    {
      id: 2,
      link: "/consultancy",
      limg: card2Min,
      himg: card2,
      title: "Consultation & Graphics",
      subtitle: "Effective Design Solutions for Your Business",
      description:
        "We take a collaborative and strategic approach to design, ensuring your brand stands out.",
    },
    {
      id: 3,
      link: "/seo",
      limg: card3Min,
      himg: card3,
      title: "Search Engine Optimization",
      subtitle: "Maximizing Your Visibility with SEO",
      description:
        "Elevate your online presence with our data-driven SEO strategies tailored for growth.",
    },
    {
      id: 4,
      link: "/digitalMarketing",
      limg: card4Min,
      himg: card4,
      title: "Digital Marketing",
      subtitle: "Best Practices for Digital Marketing Success",
      description:
        "We specialize in digital marketing strategies that ensure your brand shines online.",
    },
  ];

  return (
    <section className="flex flex-col items-center px-4 sm:px-6 lg:px-12 font-poppins">
      <div className="text-[#6E6E6E] text-center py-10">
        <h2 className="mb-4 text-3xl md:text-4xl lg:text-5xl tracking-tight font-semibold text-[#FFBF00] font-inter">
          Things <span className="text-[#6E6E6E]">to know</span>
        </h2>
        <p className="max-w-3xl text-base md:text-lg mt-6 leading-relaxed">
          At Mind Matrix, our industry and technology experts provide valuable
          insights to help you overcome challenges, innovate, and stay ahead in
          the ever-evolving business landscape.
        </p>
      </div>

      <Slider ref={sliderRef} {...settings} className="w-full h-full">
        {cards.map((card) => (
          <div
            key={card.id}
            className="px-3 cursor-pointer group text-[#6E6E6E]"
          >
            <Link
              to={card.link}
              className="flex flex-col justify-center items-center border border-[#6E6E6E] rounded-xl group-hover:border-[#FFBF00] overflow-hidden"
            >
              <div className="w-full h-52 flex items-center justify-center group-hover:scale-105 transition-transform ease-in-out duration-300">
                <img
                  src={card.limg}
                  alt={card.title}
                  className="object-cover object-center w-full h-full"
                />
              </div>

              <div className="px-4 sm:px-6 py-2 flex flex-col justify-between">
                <p className="text-sm sm:text-base py-2 font-semibold border-b-2 border-[#FFBF00] group-hover:text-[#FFBF00]">
                  {card.title}
                </p>

                <p className="my-4 text-lg sm:text-xl font-medium leading-tight group-hover:text-[#FFBF00]">
                  {card.subtitle}
                </p>

                <p className=" text-sm text-[#6E6E6E] leading-relaxed line-clamp-2">
                  {card.description}
                </p>

                <p className="text-sm leading-relaxed text-blue-500">
                  learn more
                </p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>

      <div className="flex justify-center gap-4 mt-8">
        <button
          className="border-2 border-[#FFBF00] rounded-full p-3 hover:bg-yellow-100"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M15 19l-7-7 7-7"
              stroke="#FFBF00"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button
          className="border-2 border-[#FFBF00] rounded-full p-3 hover:bg-yellow-100"
          onClick={() => sliderRef.current.slickNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M9 5l7 7-7 7"
              stroke="#FFBF00"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}

export default Blogs;
