import React from "react";
import EducationHero from "../components/Education/EducationHero";
import InteractiveLearning from "../components/Education/InteractiveLearning";

const Education = () => {
  return (
    <>
      <EducationHero />
      <InteractiveLearning />
    </>
  );
};

export default Education;
