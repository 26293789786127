import React from "react";
import featuresbg from "../assets/features-bg.png";

function FeaturesForm() {
  return (
    <section className="bg-white text-[#6E6E6E] px-4 sm:px-6 md:px-8 lg:px-12 py-12 flex flex-col lg:flex-row gap-12 lg:gap-4 justify-center">
      <div className="flex-1 font-poppins">
        <h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl tracking-tight font-semibold text-[#FFBF00] font-inter">
          <span className="text-[#6E6E6E]">About</span> Us
        </h1>

        <p className="text-sm sm:text-base md:text-lg mt-6 leading-relaxed">
          At Mind Matrix, we are on a mission to make learning more accessible,
          engaging, and impactful for students and educators worldwide.
          <br />
          <br />
          We offer cutting-edge tools and platforms designed to transform
          education—whether you're a student eager to expand your skills or an
          educator looking for innovative ways to engage learners. Our solutions
          make learning smarter, interactive, and personalized.
          <br />
          <br />
          Our services include AI-powered personalized learning paths,
          interactive online courses, and gamified content. By leveraging modern
          technology, we bridge the gap between traditional education and the
          digital age, ensuring a dynamic and effective learning experience.
        </p>

        <div className="mt-12">
          <a
            href="#contact"
            className="px-6 py-3 text-sm sm:text-base md:text-lg font-medium text-center text-white bg-[#FFBF00] hover:bg-yellow-600 focus:ring-4 rounded-lg"
          >
            Get in Touch
          </a>
        </div>
      </div>

      {/* Image */}
      <div className="flex-1">
        <img
          src={featuresbg}
          alt="feature"
          className="w-full h-full object-cover"
        />
      </div>
    </section>
  );
}

export default FeaturesForm;
