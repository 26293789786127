import React from "react";
import img from "../../assets/digital1.png";
import img1 from "../../assets/digital2.png";

const ExploreDigital = () => {
  const services = [
    {
      title: "Social Media Management",
      description:
        "We tailor our approach to your unique needs, creating strategies that resonate with your audience.",
      icon: (
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
          ></path>
        </svg>
      ),
    },
    {
      title: "Content Strategy & Beyond",
      description:
        "Create content that captivates and converts. Reach your audience through blogs, videos, and more.",
      icon: (
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
        </svg>
      ),
    },
    {
      title: "Facebook Ads",
      description:
        "Facebook is a treasure trove of opportunities for businesses. Our Facebook Ads services help you leverage this platform.",
      icon: (
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
        </svg>
      ),
    },
    {
      title: "Google Ads",
      description:
        "Our Google Ads services are designed to place your business at the forefront of relevant search results.",
      icon: (
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
        </svg>
      ),
    },
  ];

  const campaigns = [
    {
      title: "Tailored Facebook Ad Campaigns",
      description:
        "We design ad campaigns that match your brand’s needs, audience, and goals. Whether for brand awareness, lead generation, or sales, our strategies drive results.",
    },
    {
      title: "Expertise and Creativity",
      description:
        "Our team of Facebook Ads specialists blends data-driven strategies with compelling ad creatives. We craft engaging campaigns that convert.",
    },
    {
      title: "Staying Ahead of Trends",
      description:
        "The digital landscape evolves rapidly. We keep your campaigns ahead by leveraging the latest trends and updates in Facebook advertising.",
    },
  ];

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-12 font-poppins text-[#6E6E6E]">
      <div className="py-8 flex flex-col justify-center items-center text-center">
        <h1 className="md:text-4xl text-2xl text-[#6E6E6E] font-semibold tracking-tighter font-inter">
          <span className="text-[#FFBF00]">Explore the</span> Digital Universe
        </h1>
        <p className="max-w-3xl mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
          At Mind Matrix, {`we're `}your gateway to the digital landscape. We
          specialize in understanding the intricacies of digital marketing and
          ensuring your brand stands out in the digital realm.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 py-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="block rounded-xl border border-gray-100 bg-gray-100 p-6 shadow-sm hover:border-orange-400 hover:ring-1 hover:ring-orange-400 focus:outline-none focus:ring hover:bg-orange-50 transition duration-500"
            href=""
          >
            <span className="inline-block rounded-lg bg-orange-100 p-3">
              {service.icon}
            </span>
            <h2 className="mt-2 font-bold text-[#FFBF00]">{service.title}</h2>
            <p className="mt-1 text-sm text-[#6E6E6E] ">
              {service.description}
            </p>
          </div>
        ))}
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-6 lg:gap-0 py-8 mx-auto">
        <div className="flex-1">
          <h2 className="tracking-tight font-semibold md:text-4xl text-2xl text-[#6E6E6E] font-inter text-nowrap">
            <span className="text-[#FFBF00]"> Social Media </span> Management
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Social Media Marketing (SMM) is the driving force behind brand
            success. Mind Matrix takes pride in being your partner in this dynamic
            journey.
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Our SMM experts create tailored strategies that resonate with your{" "}
            {`brand's`} values and goals. We keep our fingers on the pulse of
            social media trends and platform changes, ensuring your brand
            remains ahead of the curve. Mind Matrix employs data analytics to make
            informed decisions. We measure the impact of our campaigns and
            continuously optimize for success.
          </p>
        </div>
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img}
          alt="education"
        />
      </div>

      <div className="flex flex-col-reverse lg:flex-row items-center justify-center gap-6 lg:gap-0 py-8 mx-auto">
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img1}
          alt="education"
        />
        <div className="flex-1">
          <h2 className="tracking-tight font-semibold text-2xl md:text-4xl text-[#6E6E6E] font-inter">
            <span className="text-[#FFBF00]"> Content Strategy </span> & Beyond
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            {` We're`} not just content creators: {`We're`} storytellers who
            understand the alchemy of content strategy and its power to connect
            with your audience.
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Mind Matrix crafts content strategies that align perfectly with your{" "}
            {`brand's`} identity and objectives. Your content strategy is as
            unique as your brand. We understand that {`content's`} primary
            purpose is to convert leads into customers. Our strategies are
            geared towards turning engagement into tangible results. Ready to
            craft a content strategy that captivates and converts?
          </p>
        </div>
      </div>

      <div className="py-8 flex flex-col justify-center items-center text-center">
        <div className="flex-1">
          <h2 className="tracking-tight font-semibold text-2xl md:text-4xl text-[#6E6E6E] font-inter">
            <span className="text-[#FFBF00]"> Facebook </span> Ads
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            {` Mind Matrix's`} Facebook Ads services are your key to unlocking the
            incredible potential of this platform.
          </p>
          
          <div className="w-full max-w-4xl mx-auto mt-6 p-4 lg:p-8 bg-gray-200 rounded-lg shadow-lg">
            {campaigns.map((campaign, index) => (
              <div key={index} className="mb-6">
                <h2 className="inline-block px-4 py-2 mb-2 text-sm font-bold tracking-wider text-orange-400 rounded-md bg-gray-800">
                  {campaign.title}
                </h2>
                <div className="relative bg-gray-800 border-l-4 border-orange-400 p-5 rounded-lg shadow-md transition hover:shadow-lg">
                  <p className="text-gray-300">{campaign.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreDigital;
