import React from "react";
import MobileInput from "./MobileInput";
import bg from "../assets/hero-bg.gif";

function Hero() {
  return (
    <div
      className={`w-full min-h-[50vh] md:min-h-[70vh] lg:min-h-[84vh] px-4 sm:px-6 md:px-8 lg:px-12 relative flex flex-col justify-center  bg-cover bg-no-repeat`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-10 pt-6 sm:pt-24 md:pt-36">
        <div className="w-full mx-auto z-50 text-center text-white">
          <MobileInput />
        </div>
      </div>
    </div>
  );
}

export default Hero;
