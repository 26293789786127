import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/mindmatrix.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Handles navigation and scrolling
  const handleClick = (targetId) => {
    // Navigate to home if not already there
    navigate("/", { replace: false });

    // Ensure scroll happens after navigation
    setTimeout(() => {
      const section = document.getElementById(targetId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);

    // Close the mobile menu
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const menuItems = [
    { name: "Home", path: "home" },
    { name: "About Us", path: "mindfeatures" },
    { name: "Insights", path: "blogs" },
    { name: "Services", path: "services" },
    { name: "Culture", path: "culture" },
    { name: "Contact", path: "contact" },
  ];

  return (
    <nav className="bg-white py-1 transition-shadow duration-300 border-b-4 border-transparent px-4 sm:px-6 md:px-8 lg:px-12 shadow-xl">
      <div className="flex flex-wrap items-center justify-between mx-auto">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src={logo}
            alt="Mindmatrix"
            className="h-20  w-auto object-contain"
          />
        </Link>

        {/* Hamburger Button */}
        <button
          type="button"
          aria-label="Toggle menu"
          className="inline-flex items-center p-2 ml-1 text-sm text-[#6E6E6E] rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-[#FFBF00]"
          onClick={toggleMenu}
        >
          <svg
            className={`w-9 h-9 ${isOpen ? "hidden" : "block"}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <svg
            className={`w-9 h-9 ${isOpen ? "block" : "hidden"}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {/* Desktop Menu */}
        <div className="hidden w-full items-center justify-between md:flex md:w-auto lg:order-1">
          <ul className="flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0 items-center">
            {menuItems.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => handleClick(item.path)}
                  className={`block py-2 pl-3 pr-4 rounded ${
                    item.name === "Home"
                      ? "text-[#FFBF00] font-semibold border-b-2 border-[#FFBF00]"
                      : "text-[#6E6E6E] hover:text-[#FFBF00] hover:border-b-2 hover:border-[#FFBF00]"
                  } md:p-0`}
                  aria-current={item.name === "Home" ? "page" : undefined}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="w-full md:hidden mt-4"
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
              <ul className="flex flex-col font-medium">
                {menuItems.map((item, index) => (
                  <motion.li
                    key={item.name}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.1 * index }}
                  >
                    <button
                      onClick={() => handleClick(item.path)}
                      className={`block py-2 pl-3 pr-4 rounded ${
                        item.name === "Home"
                          ? "text-[#FFBF00] font-semibold"
                          : "text-[#6E6E6E] hover:text-[#FFBF00]"
                      }`}
                      aria-current={item.name === "Home" ? "page" : undefined}
                    >
                      {item.name}
                    </button>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}

export default Navbar;
