import React from "react";
import video from "../../assets/seoo.mp4";
import img1 from "../../assets/seo1.png";
import img2 from "../../assets/seo2.png";

const InvestInSEO = () => {
  return (
    <div className=" px-4 sm:px-6 md:px-8 lg:px-12 font-poppins text-[#6E6E6E]">
      <div className="py-8 flex flex-col justify-center items-center text-center">
        <h1 className="md:text-4xl text-2xl text-[#6E6E6E] font-semibold tracking-tighter font-inter">
          <span className="text-[#FFBF00]"> Why Invest In </span>SEO ?
        </h1>
        <p className="max-w-3xl mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
          SEO influences almost every aspect of your business from boosting web
          traffic and visibility to building your brand and generating high
          quality leads.
        </p>
      </div>

      <div className="lg:flex gap-8 items-center justify-between py-8 mx-auto">
        <div className="flex-1">
          <video
            className="w-full h-86 rounded-2xl"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className="flex-1 mt-8 lg:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10 md:w-24 md:h-24 lg:h-24 lg:w-24 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <h2 className="md:text-4xl text-2xl text-[#6E6E6E] font-semibold tracking-tighter font-inter">
            <span className="text-[#FFBF00]"> Website </span> Auditing
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Our E-learning Solutions are built around the concept of interactive
            learning, ensuring that educators and learners experience a dynamic
            and engaging educational journey. {`Here's`} how we achieve this
          </p>
        </div>
      </div>

      <div className="space-y-12 py-8 mx-auto">
        <div className="mb-4 lg:mb-4 mx-auto ">
          <h2 className="md:text-4xl text-2xl font-semibold tracking-tighter font-inter text-[#FFBF00]">
            Comprehensive Keyword{" "}
            <span className="text-[#6E6E6E]">
              {" "}
              Research And Content Optimization
            </span>
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            After getting an idea of where you stand with your website and
            rankings, {`we'll`} carry out keyword research to find top
            sales-driving search terms for your business. This activity includes
            revealing keywords that your competitors rank for, digging for new
            keyword opportunities, as well as a lot of filtering and analysis.
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            In the end, {`we'll`} come up with the most powerful list of
            keywords to further optimize your website
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-0 py-8 mx-auto">
        <div className="flex-1">
          <h2 className="md:text-4xl text-2xl font-semibold tracking-tighter font-inter text-[#FFBF00]">
            Improving <span className="text-[#6E6E6E]"> UX</span>
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            User experience has a massive impact on almost every aspect of your
            business including conversions, rankings, bounce rates, your
            {`brand's `}reputation, etc. Therefore, {`it's`} crucial for us to
            make your site meet {`Google's`} and your {`customers'`} high UX
            standards
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            {`We'll`} take care of your {`webpages'`} navigation, speed,
            accordance to search intent - simply speaking, everything to make
            your customers never want to leave.
          </p>
        </div>
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img1}
          alt="education"
        />
      </div>

      <div className="flex flex-col-reverse md:flex-row items-center justify-center gap-6 md:gap-0 py-8 mx-auto">
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img2}
          alt="education"
        />
        <div className="flex-1">
          <h2 className="md:text-4xl text-2xl font-semibold tracking-tighter font-inter text-[#FFBF00]">
            Local <span className="text-[#6E6E6E]"> Search Optimization</span>
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Did you know that 46% of all Google searches are local, with{" "}
            {`'near me'`} queries doubling in the past year? This is where your
            journey begins. Local SEO offers a golden opportunity for smaller
            businesses to shine in their own backyard.
          </p>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            At Mind Matrix, {`we're`} committed to taking you there. We craft a
            tailored local search campaign {`that's`} designed to make you a
            local star. Our comprehensive approach includes setting up and
            optimizing your Google My Business page, fine-tuning local on-page
            SEO elements, and boosting your online presence
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvestInSEO;
