import React from 'react'
import SEOHero from '../components/SEO/SEOHero'
import InvestInSEO from '../components/SEO/InvestInSEO'

const SEO = () => {
  return (
    <>
     <SEOHero />
     <InvestInSEO />
    </>
  )
}

export default SEO