import React from 'react'
import DigitalMarketingHero from '../components/DigitalMarketing/DigitalMarketingHero'
import ExploreDigital from '../components/DigitalMarketing/ExploreDigital'

const DigitalMarketing = () => {
  return (
    <>
     <DigitalMarketingHero />
     <ExploreDigital />
    </>
  )
}

export default DigitalMarketing