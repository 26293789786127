import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiendpoint from "../apiendpoint.json";

const Contact = () => {
  const [formData, setFormData] = useState({
    NameUser: "",
    Email: "",
    Subject: "",
    Message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !formData.NameUser ||
      !formData.Email ||
      !formData.Subject ||
      !formData.Message
    )
      return toast.error("All fields are required. Please complete the form.");

    setLoading(true);

    fetch(apiendpoint.submitUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Form submitted successfully!");
          setFormData({
            NameUser: "",
            Email: "",
            Subject: "",
            Message: "",
          });
        } else {
          toast.error("Failed to submit the form. Please try again.");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer autoClose={2000} hideProgressBar={true} position="top-center" pauseOnFocusLoss={false} />
      <form
        onSubmit={handleSubmit}
        className="bg-slate-900 border-t-4 border-b-4 border-[#6E6E6E] px-4 sm:px-6 lg:px-12 py-12"
      >
        <section className="text-gray-600 relative mx-auto">
          <div className="mx-auto lg:flex gap-10 flex-col-reverse lg:flex-row">
            <div className="flex-1 overflow-hidden p-10 flex items-end justify-start relative">
              <iframe
                width="100%"
                height="100%"
                marginHeight="0"
                title="Mindmatrix"
                className="absolute inset-0 rounded-lg"
                allowFullScreen=""
                frameBorder="0"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4105.413381357871!2d77.35707900732865!3d28.6543912526062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM5JzE4LjYiTiA3N8KwMjEnMzIuNyJF!5e0!3m2!1sen!2sin!4v1699073711644!5m2!1sen!2sin"
              ></iframe>
              <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                <div className="max-w-full px-4">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">
                    102, First Floor, Plot No. 13, Durga Comples, Sector-15C,
                    Vasundhara, Ghaziabad (U.P.)201012
                  </p>
                </div>
                <div className="max-w-full px-4 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <a
                    href="mailto:mindmatrixbroking@email.com"
                    className="text-blue-600 underline underline-offset-4 leading-relaxed"
                  >
                    info@mindmatrixsolutions.in
                  </a>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <p className="leading-relaxed">+91-9201101499</p>
                </div>
              </div>
            </div>

            <div className="flex-1 bg-slate-900 mt-12 lg:mt-0 max-w-[420px] mx-auto">
              <h2 className=" md:text-3xl lg:text-4xl text-2xl mb-1 font-extrabold title-font text-[#FFBF00]">
                <span className="text-[#ffff]">Reach</span> out to us
              </h2>
              <p className="leading-relaxed mb-5 text-gray-200">
                Our team is available from 10 AM - 8 PM (Monday to Saturday)
              </p>
              <div className="relative mb-4">
                <label
                  htmlFor="NameUser"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Mention your name here"
                  id="NameUser"
                  name="NameUser"
                  value={formData.NameUser}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded focus:border-blue-600 focus:ring-2 focus:ring-blue-600/30 text-base outline-none text-white py-1 placeholder:text-sm placeholder:text-gray-300 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Email"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="Email"
                  name="Email"
                  placeholder="alsion@gmail.com"
                  value={formData.Email}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded focus:border-blue-600 focus:ring-2 focus:ring-blue-600/30 text-base outline-none text-white py-1 placeholder:text-sm px-3 leading-8 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Subject"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="Subject"
                  name="Subject"
                  placeholder="Subject"
                  value={formData.Subject}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded focus:border-blue-600 focus:ring-2 focus:ring-blue-600/30 text-base outline-none text-white py-1 placeholder:text-sm px-3 leading-8 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Message"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Message
                </label>
                <textarea
                  id="Message"
                  name="Message"
                  placeholder="Write a message to our team.."
                  value={formData.Message}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded focus:border-blue-600 focus:ring-2 focus:ring-blue-600/30 h-32 text-base outline-none text-white py-1 placeholder:text-sm px-3 resize-none leading-6 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button
                type="submit"
                className="text-gray-800 font-semibold bg-[#FFBF00] border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-lg"
              >
                {loading ? "Sending..." : "Submit"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Contact;
