import React from "react";
import img from "../../assets/graphics-design2.svg";

const DiveOurPortfolio = () => {
  const services = [
    {
      title: "Logo Design",
      description: "Craft a Memorable and Meaningful Brand Identity.",
      details:
        "Our designers blend creativity and strategic thinking to craft logos that not only look visually appealing but also encapsulate the essence of your brand. From color psychology to typography, we meticulously design logos that resonate with your target audience and establish a memorable brand identity.",
    },
    {
      title: "Print Materials",
      description:
        "Design Brochures, Flyers, and Business Cards that Stand Out.",
      details:
        "Our print design services go beyond aesthetics - we aim to create materials that captivate your audience. Whether it's a professionally designed brochure, an eye-catching flyer, or a memorable business card, our team ensures that every print material speaks volumes about your brand, making it stand out in a crowded market.",
    },
    {
      title: "Web Graphics",
      description: "Enhance Your Online Presence with Compelling Visuals.",
      details:
        "Our web graphics design service focuses on creating captivating visuals for your website that not only enhance user experience but also communicate your brand story effectively. From stunning website banners to engaging infographics, we bring your online presence to life with thoughtful and impactful web graphics.",
    },
    {
      title: "Social Media Graphics",
      description: "Engage Your Audience with Stunning Social Media Posts.",
      details:
        "Social media is a dynamic platform, and your brand deserves to shine amidst the scrolling feeds. Our social media graphics service is tailored to help you make a strong impact. We design visually striking and shareable content that not only aligns with your brand identity but also encourages engagement. From eye-catching Instagram posts to shareable Facebook graphics, we ensure your brand stays top-of-mind on social media.",
    },
  ];

  return (
    <div className=" px-4 sm:px-6 md:px-8 lg:px-12 font-poppins text-[#6E6E6E]">
      <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-0 py-8 mx-auto">
        <div className="flex-1">
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Dive into our portfolio and witness the magic of creativity! Our
            latest design projects stand as living testaments to our unwavering
            commitment to excellence. Each project is more than just a visual
            masterpiece; it's a journey of innovation, precision, and a deep
            understanding of our clients' unique needs. From captivating brand
            identities to compelling marketing collateral, our showcase reflects
            our dedication to delivering designs that not only meet but exceed
            the expectations of our valued clients.
          </p>
        </div>
        <img
          className="w-full max-w-xs md:max-w-sm lg:max-w-md flex-1"
          src={img}
          alt="education"
        />
      </div>

      <div className="py-8 mx-auto">
        <h1 className="md:text-4xl text-2xl text-[#6E6E6E] font-semibold tracking-tighter font-inter">
          <span className="text-[#FFBF00]">Our Approach</span>
        </h1>

        <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
          We believe in a collaborative and strategic approach to design. Our
          process involves understanding your brand, audience, and objectives to
          create designs that not only look great but also serve a purpose. From
          concept development to final execution, we ensure a seamless design
          journey.
        </p>
      </div>

      <div className="space-y-12 py-8 mx-auto">
        <div className="mb-4 lg:mb-4 mx-auto ">
          <h2 className="md:text-4xl text-2xl font-semibold tracking-tighter font-inter text-[#FFBF00]">
            Why Choose{" "}
            <span className="text-[#6E6E6E]">Mind Matrix for Graphics Design?</span>
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg leading-relaxed tracking-tight font-normal">
            Explore our range of graphics design services tailored to meet your
            unique needs:
          </p>
        </div>

        <ul className="divide-y divide-gray-200">
          {services.map((service, index) => (
            <li key={index} className="py-4">
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-6">
                <div className="flex-1 space-y-2">
                  <p className="text-lg md:text-xl font-semibold text-[#FFBF00] underline">
                    {service.title}
                  </p>
                  <p className="text-sm sm:text-base text-[#282828]">
                    {service.description}
                  </p>
                  <p className="text-sm sm:text-base text-[#6E6E6E]">
                    {service.details}
                  </p>
                </div>
                <button
                  className="px-4 py-2 text-sm sm:text-base font-semibold text-white bg-[#FDA512] rounded-md hover:bg-[#e08e0e] transition"
                  onClick={() => {
                    const email = "info@mindmatrixsolutions.in";
                    const subject = "Details";
                    const body =
                      "I want to know more about this in details and hire your team for my project";

                    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
                      subject
                    )}&body=${encodeURIComponent(body)}`;

                    window.location.href = mailtoLink;
                  }}
                >
                  Select
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DiveOurPortfolio;
