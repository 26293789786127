import React from "react";

const WhyChooseUs = () => {
  const cardData = [
    {
      title: "Customer-Centric Approach",
      description:
        "Your success drives us. With a customer-first approach, we anticipate your needs, collaborate closely, and deliver personalized solutions.",
    },
    {
      title: "Reliability and Trust",
      description:
        "Trust and reliability are at our core. We build lasting client relationships by keeping our promises and supporting you throughout your IT journey.",
    },
    {
      title: "Your Success, Our Priority",
      description:
        "At Mind Matrix, your success is our priority. We are committed to helping you achieve your IT goals and elevate your business to new heights.",
    },
    {
      title: "Cost-Efficiency",
      description:
        "We value your IT budget. Our cost-effective services deliver high quality while minimizing unnecessary expenses.",
    },
  ];

  return (
    <section className="mx-auto px-4 md:px-8 lg:px-12 xl:px-16 py-24 font-poppins text-[#6E6E6E]">
      <div className="w-full mb-8 lg:mb-12 mx-auto justify-center text-center">
        <h2 className="mb-4 text-3xl md:text-4xl lg:text-5xl tracking-tight font-semibold text-[#FFBF00] text-center font-inter">
          Why <span className="text-[#6E6E6E]">Choose Us</span>
        </h2>
        <p className="max-w-3xl text-sm sm:text-base md:text-lg mt-6 leading-relaxed mx-auto">
          We customize our solutions to fit your unique needs, delivering
          excellence and ensuring your success every step of the way.
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 mx-auto rounded-3xl overflow-hidden">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="flex flex-col sm:flex-row transition-transform duration-300 ease-out bg-gray-800 hover:bg-[#1E3A8A] text-white px-4 py-8 md:px-5 shadow-md border-b-4 border-gray-400 cursor-pointer hover:border-b-4 hover:border-[#FDA512]"
          >
            <div>
              <h6 className="mb-5 font-medium leading-5 tracking-[1px] md:text-xl text-lg">
                {card.title}
              </h6>
              <p className="text-md tracking-tight text-gray-300">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
