import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import apiendpoint from "../apiendpoint.json";

function MobileInput() {
  const [formData, setFormData] = useState({ mobile: "" });
  const [loading, setLoading] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.mobile.trim()) {
      return toast.error("Please enter your mobile number.");
    }

    setLoading(true);

    const payload = {
      ...formData,
      NameUser: "",
      Email: "",
      Subject: "",
      Message: "",
    };

    try {
      const response = await fetch(apiendpoint.submitUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Failed to submit.");
      }

      toast.success("Mobile number submitted successfully!");
      setFormData({ mobile: "" });
    } catch (error) {
      toast.error(error.message || "An error occurred while submitting.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-4xl mx-auto px-4 sm:px-6"
      >
        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center bg-opacity-85 opacity-85 text-black">
          {/* Input Field */}
          <input
            type="text"
            name="mobile"
            className="rounded-lg px-4 py-2 md:px-6 md:py-3 w-full sm:flex-1 bg-white bg-opacity-90 placeholder:text-sm placeholder:text-[#6E6E6E] border-2 border-[#6E6E6E] focus:outline-none focus:ring-2 focus:ring-[#FFBF00] text-base sm:text-lg"
            placeholder="Please provide your mobile number to request a call."
            value={formData.mobile}
            onChange={handleChange}
          />

          {/* Submit Button */}
          <button
            type="submit"
            className="px-6 py-2 md:px-8 md:py-3 bg-[#FFBF00] text-base md:text-xl text-white rounded-lg font-semibold hover:bg-yellow-600 focus:outline-none transition-all duration-300"
            disabled={loading}
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>
      </form>
      <Toaster position="top-center" />
    </>
  );
}

export default MobileInput;
