import React from "react";
import Homepage from "./pages/Homepage";
import { Route, Routes } from "react-router-dom";
import "animate.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Education from "./pages/Education";
import Consultancy from "./pages/Consultancy";
import SEO from "./pages/SEO";
import DigitalMarketing from "./pages/DigitalMarketing";
import Careers from "./pages/Careers";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="max-w-7xl min-w-80 w-full mx-auto">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/education" element={<Education />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/digitalMarketing" element={<DigitalMarketing />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/termAndCondition" element={<TermsAndCondition />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
