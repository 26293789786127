import React from "react";

function BannerNav({ links }) {
  return (
    <nav className="absolute top-4 left-4 bg-white bg-opacity-80 z-50 rounded-md px-3 py-2 shadow-md">
      <div className="flex items-center space-x-2">
        {links.map((link, index) => (
          <React.Fragment key={link.url || index}>
            {index > 0 && <span className="mx-1 text-gray-600">/</span>}
            <a
              href={link.url}
              className={`text-sm font-medium ${
                index === 0
                  ? "text-blue-500 underline underline-offset-2"
                  : link.active
                  ? "text-white bg-blue-700 px-2 py-1 rounded"
                  : "text-gray-900 hover:text-blue-700"
              }`}
            >
              {link.label}
            </a>
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
}

export default BannerNav;
