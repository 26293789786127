import React from 'react'
import ConsultancyHero from '../components/Consultancy/ConsultancyHero'
import DiveOurPortfolio from '../components/Consultancy/DiveOurPortfolio'

const Consultancy = () => {
  return (
    <>
     <ConsultancyHero />
     <DiveOurPortfolio />
    </>
  )
}

export default Consultancy